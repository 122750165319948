/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { MenuItem, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import AddOutStanding from 'src/components/modal/addOutanding';
import { TableMoreMenu } from 'src/components/table';
import formatCurrency from 'src/utils/format-current';
// components
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies

// ----------------------------------------------------------------------

SamplesRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  getOutstanding: PropTypes.func,
};

export default function SamplesRow({ row, selected, getOutstanding }) {
  const { familyName, givenName, middleName, outstanding, updatedAt, customers, products ,quantity } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left" variant="subtitle2" sx={{ cursor: 'pointer' }}>
        {customers?.salesman?.fullName}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {customers?.name}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {moment(updatedAt).format('DD-MM-YYYY')}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {products?.name}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {quantity}
      </TableCell>
    </TableRow>
  );
}
