import { Helmet } from 'react-helmet-async';
import AuthClassicLayout from 'src/layouts/auth/classic';
import LoginView from 'src/sections/auth/login-view';

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AuthClassicLayout>
        <LoginView />
      </AuthClassicLayout>
    </>
  );
}
