import { Container, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import BankingBalanceStatistics from "src/components/graph/BankingBalanceStatistics";
import { useSettingsContext } from "src/components/settings";
import AxiosAll from "src/service/axiosAll";

export default function UserReport() {
    // By default 12 months data will be shown.
    const [chooseOption, setChooseOption] = useState('3 Months');
    const [labelData, setLabelData] = useState([]);
    const [targetArr, setTargetArr] = useState([]);
    const [acheivedArr, setAcheivedArr] = useState([])
    const [label, setLabel] = useState(labelData)
    const { themeStretch } = useSettingsContext();
    const params = useParams();

    // get redux state
    const user = useSelector((state) => state.user);
    const { token } = user;

    // On changing option label will be sliced or changed.
    useEffect(() => {
        if (chooseOption === "3 Months") {
            setLabel(labelData.slice(-3))
        } else if (chooseOption === "6 Months") {
            setLabel(labelData.slice(-6))
        } else if (chooseOption === "12 Months") {
            setLabel(labelData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chooseOption])

    // Get Target API
    const getTargets = async () => {
        const response = await AxiosAll('get', `users/fetch-all-targets/${params?.id}`, {}, token);
        const { data } = response;

        if (data?.statusCode === 200) {
            setLabelData(data?.data?.chart?.chartLabels);
            setTargetArr(data?.data?.chart?.targetAmounts);
            setAcheivedArr(data?.data?.chart?.achievedAmounts)
        }
    };

    // Settling label data initially.
    useEffect(() => {
        setLabel(labelData.slice(-3))
    }, [labelData])

    useEffect(() => {
        getTargets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page title="Performances : Graph">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Performances Graph"
                    links={[{ name: 'Dashboard' }, { name: 'Graphs' }]}
                />
                {/* <Grid container spacing={3}> */}

                <Grid item xs={12} md={8}>
                    <Stack spacing={3}>
                        <BankingBalanceStatistics
                            title="Month Wise Data (In Lakhs)"
                            chartLabels={label}
                            setChooseOption={setChooseOption}
                            chartData={[
                                {
                                    year: '12 Months',
                                    data: [
                                        { name: 'Target', data: targetArr },
                                        { name: 'Achieved', data: acheivedArr },
                                    ],
                                },
                                {
                                    year: '6 Months',
                                    data: [
                                        { name: 'Target', data: targetArr.slice(-6) },
                                        { name: 'Achieved', data: acheivedArr.slice(-6) },
                                    ],
                                },
                                {
                                    year: '3 Months',
                                    data: [
                                        { name: 'Target', data: targetArr.slice(-3) },
                                        { name: 'Achieved', data: acheivedArr.slice(-3) },
                                    ],
                                },

                            ]}
                        />

                    </Stack>
                </Grid>
                {/* </Grid> */}
            </Container>
        </Page>
    );
}