import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AuthRoutes from './auth';
import { usePathname } from './hooks';
import PublicRoutes from './public';

export default function RouterProvider() {
  const user = useSelector((state) => state.user);
  const pathname = usePathname();

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/login');
  //   } else if (user && pathname === '/login') {
  //     navigate(user?.roleId === 3 ? 'dashboard/outstanding' : 'dashboard/user');
  //   }
  // }, [user, navigate, pathname]);

  return <>{user?.token ? <AuthRoutes /> : <PublicRoutes />}</>;
}
