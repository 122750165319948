import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import formatAmount from 'src/utils/format-amount';
import { useSelector } from 'react-redux';
import { request } from 'src/service/request';
import { RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddOutStanding.propTypes = {
  getOutstanding: PropTypes.func,
  userData: PropTypes.object,
};

export default function AddOutStanding({ userData, getOutstanding }) {
  console.log({ userData });

  const outstandingSchema = Yup.object().shape({
    name: Yup.string(),
    outstanding: Yup.string().required('Outstanding amount is required'),
  });

  const user = useSelector((state) => state.user);
  const { token } = user;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const defaultValues = useMemo(
    () => ({
      name: `${userData?.givenName} ${userData?.middleName} ${userData?.familyName}`,
      outstanding: userData?.outstanding || '',
    }),
    [userData]
  );

  const methods = useForm({
    resolver: yupResolver(outstandingSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClickOpen = () => {
    // eslint-disable-next-line no-unused-expressions
    userData && setOpen(true);
  };

  const onSubmit = async (value) => {
    setLoading(true);
    const data = {
      outstanding: value?.outstanding.replace(/,/g, ''),
    };
    console.log({ data });
    try {
      const response = await request({
        method: 'POST',
        url: `/contacts/outstanding/${userData?.id}`,
        data,
        token,
      });
      if (response?.statusCode === 200) {
        setOpen(false);
        getOutstanding();
        reset(defaultValues);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      //   enqueueSnackbar(error?.data?.message, { variant: 'error' });
    }
  };

  return (
    <div>
      <Button className="timeBTn" onClick={handleClickOpen}>
        <Iconify icon="pajamas:assignee" />
        {userData?.outstanding ? 'Update' : 'Add'}
      </Button>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          setOpen(false);
          reset(defaultValues);
        }}
      >
        <DialogTitle>{userData?.outstanding ? 'Update' : 'Add'} Outstanding</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Client Name</LabelStyle>
                <RHFTextField name="name" disabled />
              </div>

              <div>
                <LabelStyle>Outstanding Amount</LabelStyle>
                <RHFTextField name="outstanding" type="text" formatter={formatAmount} />
              </div>
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              loading={loading}
              disabled={isSubmitting}
            >
              Submit
            </LoadingButton>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                reset(defaultValues);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
