/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { imagesAll } from 'src/assets/Images_index';
import '../scss/main.scss';
import Image from './Image';

// ----------------------------------------------------------------------

Logo.propTypes = {
  isClosed: PropTypes.bool,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ isClosed, disabledLink = false, sx }) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = isClosed ? (
    <Image src={imagesAll?.largelogo} className="logo-img " />
  ) : (
    <Image src={imagesAll?.smalllogo} className="logo-img " />
  );

  const whiteLogo = isClosed ? (
    <Image src={imagesAll?.largelogo} className="logo-img " />
  ) : (
    <Image src={imagesAll?.smalllogo} className="logo-img " />
  );

  if (disabledLink) {
    return <>{mode?.themeMode === 'dark' ? whiteLogo : logo}</>;
  }

  return (
    <RouterLink to="/dashboard/logs">{mode?.themeMode === 'dark' ? whiteLogo : logo}</RouterLink>
  );
}
