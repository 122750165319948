import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/pages/404';
import LoginPage from 'src/pages/auth/login';

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
}
