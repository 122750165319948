import packageEnv from '../package.json';
import { PreProdServer, ProdServer, stagingServer } from './Sever';
// main server controller
const EnvType = (Env) => {
  switch (Env) {
    case 1:
      return stagingServer;

    case 2:
      return PreProdServer;

    case 3:
      return ProdServer;

    default:
      return stagingServer;
  }
};
export default {
  currentEnv: EnvType(packageEnv?.env),
};
