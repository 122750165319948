

// ----------------------------------------------------------------------

import { Box, Card, CardHeader, TextField } from "@mui/material";
import { merge } from "lodash";
import PropTypes from 'prop-types';
import { useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactApexChart from "react-apexcharts";
import BaseOptionChart from "./BaseOptionChart";

BankingBalanceStatistics.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    setChooseOption: PropTypes.func,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function BankingBalanceStatistics({ title, subheader, setChooseOption, chartLabels, chartData, ...other }) {
    const [seriesData, setSeriesData] = useState('3 Months');

    const handleChangeSeriesData = (event) => {
        setSeriesData(event.target.value);
        setChooseOption(event.target.value)
    };

    const chartOptions = merge(BaseOptionChart(), {
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: chartLabels,
        },
        tooltip: {
            y: {
                formatter: (val) => `₹${val}`,
            },
        },
    });

    return (
        <Card {...other}>
            <CardHeader
                title={title}
                subheader={subheader}
                action={
                    <TextField
                        select
                        fullWidth
                        value={seriesData}
                        SelectProps={{ native: true }}
                        onChange={handleChangeSeriesData}
                        sx={{
                            '& fieldset': { border: '0 !important' },
                            '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                            '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                            '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
                        }}
                    >
                        {chartData.map((option) => (
                            <option key={option.year} value={option.year}>
                                {option.year}
                            </option>
                        ))}
                    </TextField>
                }
            />

            {chartData.map((item) => (
                <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
                    {item.year === seriesData && (
                        <ReactApexChart type="bar" series={item.data} options={chartOptions} height={364} />
                    )}
                </Box>
            ))}
        </Card>
    );
}
