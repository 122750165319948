import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/axiosAll';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/form-provider';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getUser: PropTypes.func
};

export default function AddUserModal({ open, onClose, getUser }) {
  const NewAddressSchema = Yup.object().shape({
    fName: Yup.string().required('First Name is required'),
    lName: Yup.string().required('Last Name is required'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits long'),
  });

  const user = useSelector((state) => state.user);
  const { token } = user;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      fName: '',
      lName: '',
      phone: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const handleOnClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (value) => {
    setLoading(true);
    const data1 = {
      firstName: value?.fName,
      lastName: value?.lName,
      phone: value?.phone,
    };
    try {
      const response = await AxiosAll('post', `auth/register`, data1, token);
      const { data } = response;
      if (data?.statusCode === 201 || data?.statusCode === 200) {
        handleOnClose();
        reset(defaultValues);
        setLoading(false);
        getUser()
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
      } else {
        handleOnClose();
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
        setLoading(false);
        // alert(response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error?.data?.message, { variant: 'success' });
      //   enqueueSnackbar(error?.data?.message, { variant: 'error' });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <div>Create User</div>
      </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>First Name</LabelStyle>
              <RHFTextField name="fName" label="Write First Name" />
            </div>

            <div>
              <LabelStyle>Last Name</LabelStyle>
              <RHFTextField name="lName" label="Write Last Name" />
            </div>

            <div>
              <LabelStyle>Phone Number</LabelStyle>
              <RHFTextField name="phone" label="Write Phone Number" type="number" />
            </div>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ backgroundColor: '#0351AB' }}
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
