import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Container,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
// routes
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import AddUserModal from 'src/components/modal/adduser';
import BulkUpload from 'src/components/modal/bulkUpload';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableHeadCustom, TableNoData, TableSkeleton } from 'src/components/table';
import { weeklyDropdown } from 'src/core/constant';
import useTable from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
import OutStandingRow from 'src/sections/tableRow/outstandingRow';
import OutStandingToolbar from 'src/sections/toolbar/OutstandingToolbar';
import AxiosAll from 'src/service/axiosAll';
import { request } from 'src/service/request';
import SamplesRow from 'src/sections/tableRow/samplesRow';

// ----------------------------------------------------------------------

const TABLE_HEAD_ADMIN = [
  { id: 'sales', label: 'Sales Person', align: 'left' },
  { id: 'cName', label: 'Client Name', align: 'left' },
  { id: 'logs', label: 'Last Updated', align: 'left' },
  { id: 'amount', label: 'Product Name', align: 'left' },
  { id: 'action', label: 'Quantity', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Samples() {
  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const user = useSelector((state) => state.user);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUser] = useState();

  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const [userId, setUserId] = useState(search.get('user_id') || '');
  const [date, setDate] = useState(search.get('date') || '');
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState(search.get('start_date') || '');
  const [endDate, setEndDate] = useState(search.get('end_date') || '');
  const [rangeId, setRangeId] = useState(search.get('range_id') || '');

  const handleFilterName = () => {
    setPage(0);
  };

  const getUser = async () => {
    setUser([]);
    const response = await AxiosAll('get', 'users/dropdown', {}, user?.token);
    if (response?.status === 200) {
      setUser(response?.data?.data);
    }
  };

  const getSamples = async () => {
    try {
      setLoading(true);
      const res = await request({
        method: 'get',
        url: `/samples/all-samples?limit=${rowsPerPage}&search=${query}&page=${page + 1}`,
        params: {
          page: page + 1,
          limit: rowsPerPage,
          query,
          salesPersonId: userId,
          rangeId,
          startDate,
          endDate,
        },
        token: user?.token,
      });
      setLoading(false);
      setTableData(res?.data);
      setCount(res?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSamples();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, userId, rangeId, startDate, endDate]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNotFound = !tableData?.length && !!filterStatus;

  const handleUsers = (e) => {
    navigate(
      `${location.pathname}?user_id=${e?.target?.value}&date=${date}&start_date=${startDate}&end_date=${endDate}&range_id=${rangeId}`
    );

    setUserId(e.target.value);
    setPage(0);
  };

  const handleStartDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userId}&start_date=${e?.target?.value}&end_date=${endDate}`
    );
    setPage(0);
    setStartDate(e.target.value);
    setRangeId('');
  };

  const handleEndDateChange = (e) => {
    navigate(
      `${location.pathname}?user_id=${userId}&start_date=${startDate}&end_date=${e?.target?.value}`
    );
    setPage(0);
    setEndDate(e.target.value);
    setRangeId('');
  };

  const handleRangeChange = (e) => {
    navigate(`${location.pathname}?user_id=${userId}&range_id=${e?.target?.value}`);
    setPage(0);
    setRangeId(e.target.value);
    setStartDate('');
    setEndDate('');
  };

  const ClearFilter = () => {
    setUserId('');
    setStartDate('');
    setEndDate('');
    setRangeId('');
    setQuery('');
    navigate(`${location.pathname}`);
  };

  const downloadReport = async () => {
    try {
      const res = await request({
        method: 'get',
        url: '/contacts/outstanding/download',
        params: {
          query,
          salesPersonId: userId,
          rangeId,
          startDate,
          endDate,
          limit: 100000,
        },
        responseType: 'blob',
        token: user?.token,
      });

      // Provide a default filename for the downloaded CSV file
      const fileName = 'outstanding-report.csv';

      // Trigger the download using FileSaver.js
      saveAs(res, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title="Samples">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Samples List"
          links={[{ name: 'Dashboard' }, { name: 'Sample List' }]}
        />
        <BulkUpload
          open={isBulkUploadOpen}
          setOpen={setIsBulkUploadOpen}
          getSamples={getSamples}
        />
        <AddUserModal open={open} onClose={() => setOpen(false)} />
        <Stack
          spacing={2}
          className="stack-center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ px: 0.5, py: 1.5 }}
        >
          {/* <TextField
            fullWidth
            select
            label="Salesperson"
            value={userId}
            onChange={handleUsers}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key=""
              value=""
            >
              Select
            </MenuItem>
            {users?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.firstName} {option?.lastName}
              </MenuItem>
            ))}
          </TextField> */}

          {/* Week wise */}
          {/* <div style={{ flexDirection: 'column', width: 'auto' }}> */}
          {/* <div style={{ fontSize: 12 }}>
                        Week Wise
                    </div> */}

          {/* </div> */}
          {/* <TextField
            fullWidth
            select
            label="Week wise"
            value={rangeId}
            onChange={handleRangeChange}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key=""
              value=""
            >
              Select
            </MenuItem>
            {weeklyDropdown?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </TextField> */}

          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 12 }}>Start Date</div>
            <TextField id="date1" type="date" value={startDate} onChange={handleStartDateChange} />
          </div> */}

          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 12 }}>End Date</div>
            <TextField id="date1" type="date" value={endDate} onChange={handleEndDateChange} />
          </div> */}

          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              onClick={() => ClearFilter()}
            >
              Clear
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              onClick={downloadReport}
            >
              Download
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#0351AB' }}
              onClick={() => setIsBulkUploadOpen(true)}
            >
              Bulk Upload
            </LoadingButton>
          </div> */}
        </Stack>
        <Card>
          <OutStandingToolbar
            onFilterName={handleFilterName}
            setQuery={setQuery}
            placeholder="Search Client"
          />
          <Divider />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  headLabel={TABLE_HEAD_ADMIN}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <SamplesRow key={row.id} row={row} getOutstanding={getSamples} />
                    ))}
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
