// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    logs: `${ROOTS.DASHBOARD}/logs`,
    summary: `${ROOTS.DASHBOARD}/summary`,
    report: `${ROOTS.DASHBOARD}/report`,
    outstanding: `${ROOTS.DASHBOARD}/outstanding`,
    dashboard: `${ROOTS.DASHBOARD}/user`,
    products: `${ROOTS.DASHBOARD}/products`,
    stocks: `${ROOTS.DASHBOARD}/stocks`,
    samples: `${ROOTS.DASHBOARD}/samples`,
     orders: `${ROOTS.DASHBOARD}/orders`,

    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
};
